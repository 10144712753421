import React from 'react';

import Styled from 'styled-components';

import SlidingModal from '../primespot-ui/components/slidingModal';

import { TextInput, PrimaryButton } from '../styles/forms';

const ChangePasswordModal = ({
  onClose,
  visible,
  growl,
  user,
  getToken,
  changePassword,
}) => {
  return (
    <Wrapper>
      <SlidingModal
        onClose={onClose}
        visible={visible}
        header={<h3>Change your password</h3>}
      >
        <div className="password-change-modal__content">
          <form>
            <label>
              <span>Enter your current password</span>
              <TextInput
                id="password-change__current-password"
                type="password"
                name="password-change__current-password"
              />
            </label>
            <label>
              <span>Enter new password</span>
              <TextInput
                id="password-change__new-password"
                type="password"
                name="password-change__new-password"
              />
            </label>
            <label>
              <span>Repeat new password</span>
              <TextInput
                id="password-change__new-password-2"
                type="password"
                name="password-change__new-password-2"
              />
            </label>
            <PrimaryButton
              onClick={event => {
                event.preventDefault();

                const currentPassword = document.querySelector(
                  '#password-change__current-password'
                );
                const newPassword = document.querySelector(
                  '#password-change__new-password'
                );
                const newPassword2 = document.querySelector(
                  '#password-change__new-password-2'
                );

                if (newPassword.value !== newPassword2.value) {
                  growl({
                    type: 'danger',
                    message: `Repeated password doesn't match new password.`,
                  });
                  newPassword.value = '';
                  newPassword2.value = '';
                } else if (newPassword.value.length < 8) {
                  growl({
                    type: 'danger',
                    message:
                      'New password in invalid.  Passwords must be 8 characters or longer.',
                  });
                  newPassword.value = '';
                  newPassword2.value = '';
                } else {
                  changePassword({
                    userId: user._id,
                    currentPassword: currentPassword.value,
                    newPassword: newPassword.value,
                  })
                    .then(res => {
                      growl({
                        type: 'success',
                        message: 'Password changed successfully.',
                      });
                      currentPassword.value = '';
                      newPassword.value = '';
                      newPassword2.value = '';
                      onClose();
                    })
                    .catch(err => {
                      if (err.response.status === 401) {
                        growl({
                          type: 'danger',
                          message: 'Current password is incorrect.',
                        });
                        currentPassword.value = '';
                      } else if (err.response.status === 400) {
                        growl({
                          type: 'danger',
                          message:
                            'New password in invalid.  Passwords must be 8 characters or longer.',
                        });
                        newPassword.value = '';
                        newPassword2.value = '';
                      } else {
                        growl({
                          type: 'danger',
                          message:
                            'Something went wrong with your request.  Please try again.',
                        });
                      }
                    });
                }
              }}
            >
              Change password
            </PrimaryButton>
          </form>
        </div>
      </SlidingModal>
    </Wrapper>
  );
};

const Wrapper = Styled.div`
  h3 {
    margin-top: 0;
  }

  .password-change-modal__content {
    label {
      display: block;
    }

    label span {
      display: block;
    }
  }
`;

export default ChangePasswordModal;
