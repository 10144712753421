import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
  changePassword,
} from '../../redux/actions/authentication/actionCreators';

import { growl } from '../../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../../redux/actions/authorization/actionCreators';

import Layout from '../../layouts/backendLayout';
import Toggle from '../../primespot-ui/components/toggle';
import LoadingSpinner from '../../primespot-ui/components/loadingSpinner';
import LoginForm from '../../components/loginForm';
import ChangePasswordModal from '../../components/changePasswordModal';

import Styled, { ThemeProvider } from 'styled-components';

import theme from '../../styles/theme';

import ResetPasswordModal from '../../components/resetPasswordModal';

const ProfilePage = props => {
  const {
    location,
    user,
    isLoading,
    login,
    changePassword,
    logout,
    getToken,
    growl,
  } = props;

  return (
    <Layout
      user={user}
      login={login}
      logout={logout}
      growl={growl}
      location={location}
      miniSidebar
      noPadding
    >
      <ThemeProvider theme={theme}>
        <Wrapper>
          {isLoading && <LoadingSpinnerWithOverlay />}
          {!user && (
            <Fragment>
              <LoginForm />
            </Fragment>
          )}
          {user && (
            <Fragment>
              <h1>{`${user && user.firstName} ${user && user.lastName}`}</h1>
              <Toggle>
                {({ toggle, toggled }) => (
                  <Fragment>
                    <a
                      href="#change-password"
                      onClick={ev => {
                        ev.preventDefault();

                        toggle();
                      }}
                    >
                      Change password
                    </a>
                    <ChangePasswordModal
                      onClose={toggle}
                      visible={toggled}
                      growl={growl}
                      user={user}
                      getToken={getToken}
                      changePassword={changePassword}
                    />
                  </Fragment>
                )}
              </Toggle>
              <Toggle>
                {({ toggle, toggled }) => (
                  <Fragment>
                    <a
                      href="#forgot-password"
                      onClick={ev => {
                        ev.preventDefault();

                        toggle();
                      }}
                    >
                      Forgot password
                    </a>
                    <ResetPasswordModal
                      onClose={toggle}
                      visible={toggled}
                      growl={growl}
                      user={user}
                    />
                  </Fragment>
                )}
              </Toggle>
              <a
                href="#logout"
                onClick={ev => {
                  ev.preventDefault();

                  logout();
                }}
              >
                Logout
              </a>
            </Fragment>
          )}
        </Wrapper>
      </ThemeProvider>
    </Layout>
  );
};

const LoadingSpinnerWithOverlay = () => {
  return (
    <LoadingWrapper>
      <LoadingSpinner color="#FFF" />
    </LoadingWrapper>
  );
};

const LoadingWrapper = Styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = Styled.div`
  padding: 12px;

  a {
    display: block;
    margin-bottom: 12px;
    text-decoration: none;
    color: ${({ theme }) => theme.primary.colors.darkBlue.base};
    transition: all .3s ease;

    &:hover {
      color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    }
  }
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    isLoading: state.authentication.isLoading,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
    changePassword: ({ userId, newPassword, currentPassword }) =>
      dispatch(changePassword({ userId, newPassword, currentPassword })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
